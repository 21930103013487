import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { executePrivateTx } from "../../api/api";
import { toast } from "react-toastify";

function separateFunctions(abi) {
  if (!abi || abi === undefined || abi === "") abi = [];
  const readFunctions = [];
  const writeFunctions = [];

  abi.forEach((item) => {
    if (item.type === "function") {
      const functionDetails = {
        name: item.name,
        inputs: item.inputs.map((input) => ({
          name: input.name,
          type: input.type,
        })),
      };

      if (item.stateMutability === "view" || item.stateMutability === "pure") {
        readFunctions.push(functionDetails);
      } else {
        writeFunctions.push(functionDetails);
      }
    }
  });

  return { readFunctions, writeFunctions };
}

function Private() {
  const [tokenAddress, setTokenAddress] = useState("");
  const [priorityFee, setPriorityFee] = useState(0);
  const [addOnFee, setAddOnFee] = useState(0);
  const [enableTradingGasLimit, setEnableTradingGasLimit] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [abi, setAbi] = useState([]);
  const [readFunctions, setReadFunctions] = useState([]);
  const [readInputs, setReadInputs] = useState([]);
  const [writeFunctions, setWriteFunctions] = useState([]);
  const [writeInputs, setWriteInputs] = useState([]);
  const [tradingTrigger, setTradingTrigger] = useState({});
  const [tradingStatus, setTradingStatus] = useState({});
  const [primaryPk, setPrimaryPk] = useState("");
  const [waitBlocks, setWaitBlocks] = useState(4);

  useEffect(() => {
    const { writeFunctions, readFunctions } = separateFunctions(abi);
    setWriteFunctions(writeFunctions);
    setReadFunctions(readFunctions);
  }, [abi]);

  return (
    <>
      <Typography variant="h4" mb={2}>
        Private Transaction Module
      </Typography>
      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid container spacing={2} padding={2}>
          <Grid item xs={12} md={6}>
            <TextField
              value={tokenAddress}
              variant="outlined"
              label="Contract Address"
              onChange={(e) => setTokenAddress(e.target.value)}
              fullWidth
              style={{ marginBottom: "15px" }}
            />
            <TextField
              value={waitBlocks}
              variant="outlined"
              label="Number of blocks to wait"
              onChange={(e) => setWaitBlocks(parseInt(e.target.value))}
              fullWidth
              style={{ marginBottom: "10px" }}
            />
            <TextField
              value={primaryPk}
              variant="outlined"
              label="Private key"
              onChange={(e) => setPrimaryPk(e.target.value)}
              fullWidth
              style={{ marginBottom: "15px" }}
            />
            <TextField
              value={priorityFee}
              variant="outlined"
              label="Priority Fee (gwei)"
              onChange={(e) => setPriorityFee(parseInt(e.target.value))}
              fullWidth
              style={{ marginBottom: "15px" }}
            />
            <TextField
              value={addOnFee}
              variant="outlined"
              label="Add On Fee (gwei)"
              onChange={(e) => setAddOnFee(parseInt(e.target.value))}
              fullWidth
              style={{ marginBottom: "15px" }}
            />
            <TextField
              value={enableTradingGasLimit}
              variant="outlined"
              label="Gas limit"
              onChange={(e) =>
                setEnableTradingGasLimit(parseInt(e.target.value))
              }
              fullWidth
              style={{ marginBottom: "15px" }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              value={JSON.stringify(abi)}
              variant="outlined"
              label="ABI (JSON Array)"
              onChange={(e) => {
                try {
                  setAbi(JSON.parse(e.target.value));
                } catch (err) {
                  toast("Please input a valid abi", {
                    type: "error",
                  });
                }
              }}
              fullWidth
              style={{ marginBottom: "15px" }}
            />
            {abi && abi.length > 0 && (
              <>
                <FormControl fullWidth style={{ marginBottom: "15px" }}>
                  <InputLabel>Status function</InputLabel>
                  <Select
                    value={tradingStatus}
                    label="Status function"
                    onChange={(e) => {
                      const trig = e.target.value;
                      setTradingStatus(trig);
                      setReadInputs(
                        new Array(trig?.inputs?.length | 0).fill("")
                      );
                    }}
                  >
                    {readFunctions.map((fun) => (
                      <MenuItem key={fun.name} value={fun}>
                        {fun.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {tradingStatus?.inputs?.map((inp, index) => {
                  return (
                    <TextField
                      value={readInputs[index]}
                      variant="outlined"
                      label={`${inp.name} (${inp.type})`}
                      onChange={(e) => {
                        let newInputs = readInputs;
                        newInputs[index] = e.target.value;
                        setReadInputs(newInputs.slice());
                      }}
                      style={{ marginBottom: "15px" }}
                    />
                  );
                })}
                <FormControl fullWidth style={{ marginBottom: "15px" }}>
                  <InputLabel>Trigger Function</InputLabel>
                  <Select
                    value={tradingTrigger}
                    label="Trigger Function"
                    onChange={(e) => {
                      const trig = e.target.value;
                      setTradingTrigger(trig);
                      setWriteInputs(
                        new Array(trig?.inputs?.length | 0).fill("")
                      );
                    }}
                  >
                    {writeFunctions.map((fun) => (
                      <MenuItem key={fun.name} value={fun}>
                        {fun.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {tradingTrigger?.inputs?.map((inp, index) => {
                  return (
                    <TextField
                      value={writeInputs[index]}
                      variant="outlined"
                      label={`${inp.name} (${inp.type})`}
                      onChange={(e) => {
                        let newInputs = writeInputs;
                        newInputs[index] = e.target.value;
                        setWriteInputs(newInputs.slice());
                      }}
                      style={{ marginBottom: "15px" }}
                    />
                  );
                })}
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="black"
              fullWidth
              onClick={async () => {
                try {
                  setLoading(true);

                  await executePrivateTx(
                    tokenAddress,
                    priorityFee,
                    addOnFee,
                    tradingTrigger?.name,
                    writeInputs,
                    tradingStatus?.name,
                    readInputs,
                    abi,
                    primaryPk,
                    enableTradingGasLimit,
                    waitBlocks
                  );
                  setLoading(false);
                  toast("Private Tx in progress", {
                    type: "success",
                  });
                } catch (e) {
                  setLoading(false);
                  toast(e.message, {
                    type: "error",
                  });
                }
              }}
              disabled={isLoading}
              style={{ marginBottom: "15px" }}
            >
              Execute
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export default Private;
