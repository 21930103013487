export const chains = [
  { name: 'ETH', networkId: 'ethereum' },
  { name: 'POLYGON', networkId: 'polygon' },
  { name: 'BINANCE', networkId: 'binance' },
  { name: 'BASE', networkId: 'Base Chain' },
  { name: 'FANTOM', networkId: 'Fantom Opera' },
  { name: 'OPTIMISM', networkId: 'optimism' },
  { name: 'AVAX', networkId: 'avaxc-mainnet' },
];

export const tokens = {
  'ethereum': [
    { name: 'ETH', contractAddress: 'null' },
    { name: 'USDT', contractAddress: '0xdac17f958d2ee523a2206206994597c13d831ec7' },
    { name: 'USDC', contractAddress: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48' },
  ],
  'polygon': [
    { name: 'MATIC', contractAddress: 'null' },
    { name: 'USDT', contractAddress: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f' },
    { name: 'USDC', contractAddress: '0x3c499c542cef5e3811e1192ce70d8cc03d5c3359' },
  ],
  'binance': [
    { name: 'BNB', contractAddress: 'null' },
    { name: 'USDT', contractAddress: '0x55d398326f99059ff775485246999027b3197955' },
    { name: 'USDC', contractAddress: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d' },
  ],
  'Base Chain': [
    { name: 'BASE ETH', contractAddress: 'null' },
    { name: 'USDC', contractAddress: '0x833589fcd6edb6e08f4c7c32d4f71b54bda02913' },
  ],
  'Fantom Opera': [
    { name: 'ETH', contractAddress: 'null' },
    { name: 'USDC', contractAddress: '0x04068da6c83afcfa0e13ba15a6696662335d5b75' },
  ],
  'optimism': [
    { name: 'ETH', contractAddress: 'null' },
    { name: 'USDT', contractAddress: '0x94b008aa00579c1307b0ef2c499ad98a8ce58e58' },
    { name: 'USDC', contractAddress: '0x7f5c764cbc14f9669b88837ca1490cca17c31607' },
  ],
  'avaxc-mainnet': [
    { name: 'ETH', contractAddress: 'null' },
    { name: 'USDT', contractAddress: '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7' },
    { name: 'USDC', contractAddress: '0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e' },
  ],
};
