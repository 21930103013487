const useSelectAll = () => {
  const selectAll = (groupWallets, checked, setChecked) => {
    const allChecked = groupWallets.every(
      (wallet) => checked[wallet.walletIndex]
    );
    const updatedChecked = [...checked];

    groupWallets.forEach((wallet) => {
      updatedChecked[wallet.walletIndex] = !allChecked; // Toggle based on the current state
    });

    setChecked(updatedChecked); // Update the checked array
  };

  const isAll = (groupWallets = [], checked = []) => {
    return groupWallets.every((wallet) => checked[wallet.walletIndex]);
  };

  const isPartOfGroupWallet = (index, groupWallets) => {
    return groupWallets.some((wallet) => wallet.walletIndex === index);
  };

  return { selectAll, isAll, isPartOfGroupWallet };
};

export default useSelectAll;
