import axios from "axios";

// For local
export const baseURL = "/api/v1";

const axiosInstance = axios.create({
  baseURL,
});

export const getPrimaryWallet = async () => {
  const resp = await axiosInstance.get("/wallet/primary");

  return resp.data.walletAddress;
};

export const getEnvironment = async () => {
  const resp = await axiosInstance.get("/wallet/environment");

  return resp.data.envVars;
};

export const exportPrimaryWallet = async () => {
  const resp = await axiosInstance.get("/wallet/primary/export");

  return resp.data.privateKey;
};

export const generatePrimaryWallet = async (primaryPk = null) => {
  const resp = await axiosInstance.put("/wallet/primary/generate", {
    primaryPk,
  });

  return resp.data.success;
};

export const updateEnvironment = async (updatedVars) => {
  const resp = await axiosInstance.put("/wallet/environment", {
    updatedVars,
  });

  return resp.data.success;
};

export const getSecondaryWallets = async () => {
  const resp = await axiosInstance.get("/wallet/secondary");

  return { sWs: resp.data.walletAddresses, details: resp.data.details };
};

export const exportSecondaryWallet = async (index) => {
  const resp = await axiosInstance.get(`/wallet/secondary/export/${index}`);

  return resp.data.privateKey;
};

export const generateSecondaryWallet = async (num, secondaryPks = null) => {
  const resp = await axiosInstance.put("/wallet/secondary/generate", {
    num,
    secondaryPks,
  });

  return resp.data.success;
};

export const removeSecondaryWallet = async (index, address) => {
  const resp = await axiosInstance.delete(`/wallet/secondary/${index}`, {
    address,
  });

  return resp.data.success;
};

export const hideSecondaryWallet = async (address) => {
  const resp = await axiosInstance.put(`/wallet/secondary/info/${address}`, {
    isHidden: true,
  });

  return resp.data.success;
};

export const unhideSecondaryWallet = async (address) => {
  const resp = await axiosInstance.put(`/wallet/secondary/info/${address}`, {
    isHidden: false,
  });

  return resp.data.success;
};

export const updateSecondaryWalletGroups = async (address, tags) => {
  const resp = await axiosInstance.put(`/wallet/secondary/info/${address}`, {
    tags,
  });

  return resp.data.success;
};

export const distributeFunds = async (
  addresses,
  amount,
  maxAmount,
  deviation,
  avgPeriod,
  maxPeriod,
  deviationPeriod,
  limit,
  speed,
  sourceChain,
  sourceCurrency,
  destinationChain,
  destinationCurrency,
  exchangeType,
  isGlobal,
  tokenAmounts
) => {
  const resp = await axiosInstance.post(`/wallet/distribute`, {
    addresses,
    amount,
    maxAmount,
    deviation,
    avgPeriod,
    maxPeriod,
    deviationPeriod,
    limit,
    speed,
    sourceChain,
    sourceCurrency,
    destinationChain,
    destinationCurrency,
    exchangeType,
    isGlobal,
    tokenAmounts,
  });

  return resp.data.success;
};

export const distributeToWallets = async (
  tokenAddress,
  maxHolding,
  avgPeriod,
  maxPeriod,
  deviationPeriod,
  limit,
  speed,
  csvData
) => {
  const resp = await axiosInstance.post(`/wallet/spread`, {
    tokenAddress,
    maxHolding,
    avgPeriod,
    maxPeriod,
    deviationPeriod,
    limit,
    speed,
    csvData,
  });

  return resp.data.success;
};

export const collectFunds = async (
  address,
  percentAmount,
  walletIndexes,
  limit,
  speed,
  sourceChain,
  sourceCurrency,
  destinationChain,
  destinationCurrency,
  exchangeType
) => {
  const resp = await axiosInstance.post(`/wallet/collect`, {
    address,
    percentAmount,
    walletIndexes,
    limit,
    speed,
    sourceChain,
    sourceCurrency,
    destinationChain,
    destinationCurrency,
    exchangeType,
  });

  return resp.data.success;
};

export const sendToken = async (
  address,
  percentAmount,
  walletIndexes,
  tokenAddress,
  limit,
  speed
) => {
  const resp = await axiosInstance.post(`/wallet/token/send`, {
    address,
    percentAmount,
    walletIndexes,
    tokenAddress,
    limit,
    speed,
  });

  return resp.data.success;
};

export const sellAllToken = async (
  walletIndexes,
  tokenAddress,
  slippage,
  percentAmount,
  limit,
  speed,
  isUniswap,
  is1inch,
  isCow
) => {
  const resp = await axiosInstance.post(`/liquidation/sell`, {
    walletIndexes,
    tokenAddress,
    slippage,
    percentAmount,
    limit,
    speed,
    isUniswap,
    is1inch,
    isCow,
  });

  return resp.data.success;
};

export const restartServer = async () => {
  const resp = await axiosInstance.post("/wallet/restart");

  return resp.data;
};

export const getLogs = async () => {
  const resp = await axiosInstance.get("/wallet/logs");

  return resp.data;
};

export const getHistory = async () => {
  const resp = await axiosInstance.get("/wallet/history");

  return resp.data;
};

export const accumulate = async (
  walletIndexes,
  tokenAddress,
  slippage,
  avgPeriod,
  maxPeriod,
  deviationPeriod,
  amount,
  maxAmount,
  amountDeviation,
  maxHolding,
  retentionAmount,
  isPrioritized,
  limit,
  speed,
  isUniswap,
  is1inch,
  isCow
) => {
  // - Deviation in %
  // - Period in seconds
  // - Slippage is 0-1
  const resp = await axiosInstance.post("/accumulation", {
    walletIndexes,
    tokenAddress,
    slippage,
    avgPeriod,
    maxPeriod,
    deviationPeriod,
    amount,
    maxAmount,
    amountDeviation,
    maxHolding,
    retentionAmount,
    isPrioritized,
    limit,
    speed,
    isUniswap,
    is1inch,
    isCow,
  });

  return resp.data.success;
};

export const approvalSchedule = async (
  walletIndexes,
  tokenAddress,
  spenderAddress,
  avgPeriod,
  maxPeriod,
  deviationPeriod,
  amount,
  limit,
  speed
) => {
  // - Deviation in %
  // - Period in seconds
  const resp = await axiosInstance.post("/liquidation/approve", {
    walletIndexes,
    tokenAddress,
    spenderAddress,
    avgPeriod,
    maxPeriod,
    deviationPeriod,
    amount,
    limit,
    speed,
  });

  return resp.data.success;
};

export const timeLiquidate = async (
  walletIndexes,
  tokenAddress,
  slippage,
  avgPeriod,
  maxPeriod,
  deviationPeriod,
  amount,
  maxAmount,
  amountDeviation,
  limit,
  speed,
  isUniswap,
  is1inch,
  isCow
) => {
  // - Deviation in %
  // - Period in seconds
  // - Slippage is 0-1
  const resp = await axiosInstance.post("/liquidation/time", {
    walletIndexes,
    tokenAddress,
    slippage,
    avgPeriod,
    maxPeriod,
    deviationPeriod,
    amount,
    maxAmount,
    amountDeviation,
    limit,
    speed,
    isUniswap,
    is1inch,
    isCow,
  });

  return resp.data.success;
};

export const marketLiquidate = async (
  walletIndexes,
  tokenAddress,
  pairAddress,
  slippage,
  threshold,
  percentAmount,
  limit,
  speed,
  isUniswap,
  is1inch,
  isCow,
  type = "Market Liquidation",
  period = 0,
  maxPeriod = 0,
  deviation = 0,
  amount = 0,
  maxAmount = 0,
  amountDeviation = 0,
  prevBlockNum = 0,
  numOfLiquidations = 0,
  priorities = []
) => {
  // - percentAmount in %
  // - Slippage is 0-1
  const resp = await axiosInstance.post("/liquidation/market", {
    walletIndexes,
    tokenAddress,
    pairAddress,
    slippage,
    threshold,
    percentAmount,
    limit,
    speed,
    isUniswap,
    is1inch,
    isCow,
    type,
    period,
    maxPeriod,
    deviation,
    amount,
    maxAmount,
    amountDeviation,
    prevBlockNum,
    numOfLiquidations,
    priorities,
  });

  return resp.data.success;
};

export const scheduleWalletSwap = async (
  tokenAddress,
  slippage,
  gasPriceLimit,
  period,
  maxPeriod,
  periodDeviation,
  amount,
  maxAmount,
  amountDeviation,
  sellerIndexes,
  buyerIndexes,
  retentionAmount,
  limit,
  speed,
  newBuyerIndexes,
  amount2,
  maxAmount2,
  deviation2,
  delay2,
  sourceChain,
  sourceCurrency,
  destinationChain,
  destinationCurrency,
  exchangeType,
  isUniswap,
  is1inch,
  isCow
) => {
  // - Deviation in %
  // - Slippage is 0-1
  const resp = await axiosInstance.post("/swap/schedule", {
    tokenAddress,
    slippage,
    gasPriceLimit,
    period,
    maxPeriod,
    periodDeviation,
    amount,
    maxAmount,
    amountDeviation,
    sellerIndexes,
    buyerIndexes,
    retentionAmount,
    limit,
    speed,
    newBuyerIndexes,
    amount2,
    maxAmount2,
    deviation2,
    delay2,
    sourceChain,
    sourceCurrency,
    destinationChain,
    destinationCurrency,
    exchangeType,
    isUniswap,
    is1inch,
    isCow,
  });

  return resp.data.success;
};

export const swapLaunch = async (
  tokenAddress,
  slippage,
  blockWait,
  blockTime,
  period,
  maxPeriod,
  periodDeviation,
  amount,
  maxAmount,
  amountDeviation,
  amountBuy,
  maxAmountBuy,
  amountDeviationBuy,
  sellerIndexes,
  buyerIndexes,
  limit,
  speed,
  newBuyerIndexes,
  amount2,
  maxAmount2,
  deviation2,
  delay2,
  sourceChain,
  sourceCurrency,
  destinationChain,
  destinationCurrency,
  exchangeType,
  priorityFee,
  addOnFee,
  swapGasLimit,
  retries,
  gasLimitIncrement,
  minNumSellers,
  maxNumSellers,
  minNumBuyers,
  maxNumBuyers,
  isBundleShuffle,
  gasPriceLimit
) => {
  const resp = await axiosInstance.post("/swap/bundle", {
    tokenAddress,
    slippage,
    blockWait,
    blockTime,
    period,
    maxPeriod,
    periodDeviation,
    amount,
    maxAmount,
    amountDeviation,
    amountBuy,
    maxAmountBuy,
    amountDeviationBuy,
    sellerIndexes,
    buyerIndexes,
    limit,
    speed,
    newBuyerIndexes,
    amount2,
    maxAmount2,
    deviation2,
    delay2,
    sourceChain,
    sourceCurrency,
    destinationChain,
    destinationCurrency,
    exchangeType,
    priorityFee,
    addOnFee,
    swapGasLimit,
    retries,
    gasLimitIncrement,
    minNumSellers,
    maxNumSellers,
    minNumBuyers,
    maxNumBuyers,
    isBundleShuffle,
    gasPriceLimit,
  });

  return resp.data.success;
};

export const stopSwapLaunch = async () => {
  const resp = await axiosInstance.put("/swap/bundle");

  return resp.data.success;
};

export const swapAutoLaunch = async (
  tokenAddress,
  slippage,
  blockWait,
  blockTime,
  period,
  maxPeriod,
  periodDeviation,
  amount,
  maxAmount,
  amountDeviation,
  amountBuy,
  maxAmountBuy,
  amountDeviationBuy,
  limit,
  speed,
  priorityFee,
  addOnFee,
  swapGasLimit,
  retries,
  gasLimitIncrement,
  minNumSellers,
  maxNumSellers,
  minNumBuyers,
  maxNumBuyers,
  numOfBundles,
  sellerCriteria,
  buyerCriteria,
  enableDuplicates,
  isTokenRangeSeller,
  isETHRangeSeller,
  isTokenRangeBuyer,
  isETHRangeBuyer,
  minTokenRangeSeller,
  maxTokenRangeSeller,
  minEthRangeSeller,
  maxEthRangeSeller,
  minTokenRangeBuyer,
  maxTokenRangeBuyer,
  minEthRangeBuyer,
  maxEthRangeBuyer,
  sellerIndexes,
  buyerIndexes,
  isBundleShuffle,
  gasPriceLimit
) => {
  const resp = await axiosInstance.post("/swap/bundle/auto", {
    tokenAddress,
    slippage,
    blockWait,
    blockTime,
    period,
    maxPeriod,
    periodDeviation,
    amount,
    maxAmount,
    amountDeviation,
    amountBuy,
    maxAmountBuy,
    amountDeviationBuy,
    limit,
    speed,
    priorityFee,
    addOnFee,
    swapGasLimit,
    retries,
    gasLimitIncrement,
    minNumSellers,
    maxNumSellers,
    minNumBuyers,
    maxNumBuyers,
    numOfBundles,
    sellerCriteria,
    buyerCriteria,
    enableDuplicates,
    isTokenRangeSeller,
    isETHRangeSeller,
    isTokenRangeBuyer,
    isETHRangeBuyer,
    minTokenRangeSeller,
    maxTokenRangeSeller,
    minEthRangeSeller,
    maxEthRangeSeller,
    minTokenRangeBuyer,
    maxTokenRangeBuyer,
    minEthRangeBuyer,
    maxEthRangeBuyer,
    sellerIndexes,
    buyerIndexes,
    isBundleShuffle,
    gasPriceLimit,
  });

  return resp.data.success;
};

export const nonceSwap = async (
  period,
  maxPeriod,
  periodDeviation,
  gasPriceLimit,
  swapGasLimit,
  blockWait,
  blockTime,
  priorityFee,
  addOnFee,
  tokenAddresses,
  ethPercent,
  tokenPercent,
  walletIndexes,
  retries
) => {
  const resp = await axiosInstance.post("/swap/bundle/nonce", {
    period,
    maxPeriod,
    periodDeviation,
    gasPriceLimit,
    swapGasLimit,
    blockWait,
    blockTime,
    priorityFee,
    addOnFee,
    tokenAddresses,
    ethPercent,
    tokenPercent,
    walletIndexes,
    retries,
  });

  return resp.data.success;
};

export const csvNonceSwap = async (
  period,
  maxPeriod,
  periodDeviation,
  gasPriceLimit,
  swapGasLimit,
  blockWait,
  blockTime,
  priorityFee,
  addOnFee,
  csvData,
  retries
) => {
  const resp = await axiosInstance.post("/swap/bundle/nonce/csv", {
    period,
    maxPeriod,
    periodDeviation,
    gasPriceLimit,
    swapGasLimit,
    blockWait,
    blockTime,
    priorityFee,
    addOnFee,
    csvData,
    retries,
  });

  return resp.data.success;
};

export const launch = async (
  tokenAddress,
  pairAddress,
  tokenAmounts,
  priorityFee,
  priorityFeeOthers,
  addOnFee,
  addOnFeeOthers,
  enableTradingGasLimit,
  swapGasLimit,
  walletIndexes,
  readFunction,
  readParameters,
  writeFunction,
  writeParameters,
  tokenAbi,
  waitBlocks,
  primaryPk,
  isPrioritized
) => {
  const resp = await axiosInstance.post("/launch", {
    tokenAddress,
    pairAddress,
    tokenAmounts,
    priorityFee,
    priorityFeeOthers,
    addOnFee,
    addOnFeeOthers,
    enableTradingGasLimit,
    swapGasLimit,
    walletIndexes,
    readFunction,
    readParameters,
    writeFunction,
    writeParameters,
    tokenAbi,
    waitBlocks,
    primaryPk,
    isPrioritized,
  });

  return resp.data.success;
};

export const stopLaunch = async () => {
  const resp = await axiosInstance.put("/launch");

  return resp.data.success;
};

export const executePrivateTx = async (
  tokenAddress,
  priorityFee,
  addOnFee,
  writeFunction,
  writeParameters,
  readFunction,
  readParameters,
  tokenAbi,
  primaryPk,
  enableTradingGasLimit,
  waitBlocks
) => {
  const resp = await axiosInstance.post("/private", {
    tokenAddress,
    priorityFee,
    addOnFee,
    writeFunction,
    writeParameters,
    readFunction,
    readParameters,
    tokenAbi,
    primaryPk,
    enableTradingGasLimit,
    waitBlocks,
  });

  return resp.data.success;
};

export const getTokenInfo = async (ca) => {
  const resp = await axiosInstance.get(`/wallet/token/info/${ca}`);

  return resp.data;
};

export const getNonces = async (chain = "default") => {
  const resp = await axiosInstance.get(`/wallet/nonce?chain=${chain}`);

  return resp.data;
};

export const getTokens = async (wallets) => {
  const resp = await axiosInstance.post("/wallet/balance/token/all", {
    wallets,
  });

  return resp.data.tokens;
};

export const getEthBalances = async (chain = "default") => {
  const resp = await axiosInstance.get(`/wallet/balance/eth?chain=${chain}`);

  return resp.data;
};

export const getTokenBalances = async (ca, chain = "ethereum") => {
  const resp = await axiosInstance.get(
    `/wallet/balance/token/${ca}?chain=${chain}`
  );

  return resp.data;
};
