import { toast } from 'react-toastify';
import React from 'react';
import { IconButton } from '@mui/material';
import { ContentCopy, Key } from '@mui/icons-material';
import { exportPrimaryWallet, exportSecondaryWallet } from '../api/api';

const useCopyToClipboard = () => {
  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      toast('Copied to the clipboard', {
        type: 'success',
      });
    } catch (err) {
      toast(err.message, {
        type: 'error',
      });
    }
  };

  const CopyButton = ({ text }) => {
    const handleCopyClick = () => {
      copyToClipboard(text);
    };

    return (
      <IconButton onClick={handleCopyClick}>
        <ContentCopy />
      </IconButton>
    );
  };

  const PrivateKeyButton = ({ isPrimary, index = 0 }) => {
    const handleCopyClick = async () => {
      if (isPrimary) {
        const text = await exportPrimaryWallet();
        copyToClipboard(text);
      } else {
        const text = await exportSecondaryWallet(index);
        copyToClipboard(text);
      }
    };

    return (
      <IconButton onClick={handleCopyClick}>
        <Key />
      </IconButton>
    );
  };

  return { copyToClipboard, CopyButton, PrivateKeyButton };
};

export default useCopyToClipboard;
