import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  Add,
  Close,
  Done,
  Edit,
  ExpandMore,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import {
  distributeFunds,
  generatePrimaryWallet,
  generateSecondaryWallet,
  getEthBalances,
  getPrimaryWallet,
  getSecondaryWallets,
  getTokenBalances,
  hideSecondaryWallet,
  removeSecondaryWallet,
  unhideSecondaryWallet,
  updateSecondaryWalletGroups,
} from "../../api/api";
import { toast } from "react-toastify";
import { chains, tokens } from "../constants";
import useCopyToClipboard from "../../hooks/useCopyToClipboard";
import useSelectAll from "../../hooks/useSelectAll";

function Wallet() {
  const [primaryWallet, setPrimaryWallet] = useState("");
  const [secondaryWallets, setSecondaryWallets] = useState(null);
  const [amount, setAmount] = useState(0);
  const [maxAmount, setMaxAmount] = useState(0);
  const [sWallets, setSWallets] = useState(0);
  const [period, setPeriod] = useState(0);
  const [maxPeriod, setMaxPeriod] = useState(0);
  const [deviationP, setDeviationP] = useState(0);
  const [deviation, setDeviation] = useState(0);
  const [checked, setChecked] = useState([]);
  const [isEditing, setEditing] = useState([]);

  const [isLoading, setLoading] = useState(false);
  // Primary Wallet
  const [sourceBalance, setSourceBalance] = useState(null);
  const [destinationBalance, setDestinationBalance] = useState(null);
  // Secondary Wallets
  const [balances, setBalances] = useState(null);

  const [speed, setSpeed] = useState(0);
  const [label, setLabel] = useState("Slow");
  const [isLimited, setLimited] = useState(false);
  const [limit, setLimit] = useState(-1);

  const [exchangeType, setExchangeType] = useState("ANY");

  const [sourceChain, setSourceChain] = useState("ethereum");
  const [sourceCurrency, setSourceCurrency] = useState("null");
  const [destinationChain, setDestinationChain] = useState("ethereum");
  const [destinationCurrency, setDestinationCurrency] = useState("null");
  const [primaryPk, setPrimaryPk] = useState("");
  const [secondaryPks, setSecondaryPks] = useState([]);

  const [isGlobal, setGlobal] = useState(false);
  const [tokenAmounts, setTokenAmounts] = useState([]);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [newTag, setNewTag] = useState("");

  const { CopyButton, PrivateKeyButton } = useCopyToClipboard();
  const { isAll, selectAll } = useSelectAll();

  const handleSpeedLabelChange = (e) => {
    setLabel(e.target.value);
  };

  const fetchWallets = async () => {
    const pW = await getPrimaryWallet();
    setPrimaryWallet(pW);

    const { sWs, details } = await getSecondaryWallets();
    let wallets = [];
    for (let i = 0; i < sWs?.length; i++) {
      const walletWithAddress = details.find(
        (wallet) => wallet?.address?.toLowerCase() === sWs[i].toLowerCase()
      );

      if (walletWithAddress) {
        const { tags, isHidden } = walletWithAddress;
        wallets.push({
          walletIndex: i,
          tags: tags || [],
          isHidden: isHidden || false,
          address: sWs[i],
        });
      } else {
        wallets.push({
          walletIndex: i,
          tags: [],
          isHidden: false,
          address: sWs[i],
        });
      }
    }
    setSecondaryWallets(wallets);
    setChecked(new Array(sWs.length | 0).fill(false));
    setEditing(new Array(sWs.length | 0).fill(false));
    setTokenAmounts(new Array(sWs.length | 0).fill(0));
  };

  const fetchBalances = async () => {
    setLoading(true);
    if (sourceCurrency === "null") {
      const { primaryBalance } = await getEthBalances(sourceChain);
      setSourceBalance(primaryBalance);
    } else {
      const { primaryBalance } = await getTokenBalances(
        sourceCurrency,
        sourceChain
      );
      setSourceBalance(primaryBalance);
    }
    if (destinationCurrency === "null") {
      const { primaryBalance, secondaryBalances } = await getEthBalances(
        destinationChain
      );
      setDestinationBalance(primaryBalance);
      setBalances(secondaryBalances);
    } else {
      const { primaryBalance, secondaryBalances } = await getTokenBalances(
        destinationCurrency,
        sourceChain
      );
      setDestinationBalance(primaryBalance);
      setBalances(secondaryBalances);
    }

    setLoading(false);
  };

  const handleChange = async (e) => {
    const { id, checked } = e.target;
    setChecked((prev) => {
      prev[id] = checked;
      return [...prev];
    });
  };

  useEffect(() => {
    fetchWallets();
  }, []);

  useEffect(() => {
    if (label) {
      if (label === "Slow") {
        setSpeed(0);
      } else if (label === "Medium") {
        setSpeed(10);
      } else if (label === "Fast") {
        setSpeed(25);
      }
    }
  }, [label]);

  useEffect(() => {
    if (isLimited) {
      setLimit(0);
    } else {
      setLimit(-1);
    }
  }, [isLimited]);

  useEffect(() => {
    if (sWallets > -1) {
      setSecondaryPks(new Array(sWallets).fill(""));
    }
  }, [sWallets]);

  // Group wallets
  const groups = { All: [] };
  secondaryWallets?.forEach((wallet) => {
    if (!wallet.isHidden) {
      // Add to "All" group
      groups.All.push(wallet);

      // Add to tag groups
      wallet.tags.forEach((tag) => {
        if (!groups[tag]) {
          groups[tag] = [];
        }
        groups[tag].push(wallet);
      });
    }
  });

  // Add hidden group
  groups.Hidden = secondaryWallets
    ?.filter((wallet) => wallet.isHidden)
    .map((wallet) => wallet);

  return (
    <>
      <Typography variant="h4" mb={2}>
        Wallet Module
      </Typography>
      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid container spacing={4} padding={2}>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              onClick={async () => fetchBalances()}
              disabled={isLoading}
            >
              Show/Refresh Balances
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              value={primaryWallet}
              variant="outlined"
              disabled
              fullWidth
              // helperText={`Balance: ${usdtBalance ? usdtBalance : '-'} USDT`}
              style={{ marginBottom: "15px" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CopyButton text={primaryWallet} />
                    <PrivateKeyButton isPrimary={true} />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              value={primaryPk}
              variant="outlined"
              label="Primary Private Key (Optional)"
              onChange={(e) => setPrimaryPk(e.target.value)}
              fullWidth
              style={{ marginBottom: "15px" }}
            />
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              style={{ marginBottom: "25px" }}
              onClick={async () => {
                await generatePrimaryWallet(primaryPk);
                await fetchWallets();
              }}
            >
              Replace Primary Wallet
            </Button>
            <TextField
              value={sWallets}
              variant="outlined"
              label="Number of secondary wallets to generate"
              onChange={(e) => setSWallets(parseInt(e.target.value))}
              fullWidth
              style={{ marginBottom: "15px" }}
            />
            {secondaryPks.map((sPk, index) => (
              <TextField
                value={sPk}
                variant="outlined"
                label="Secondary Private Key (Optional)"
                onChange={(e) =>
                  setSecondaryPks((prev) => {
                    prev[index] = e.target.value;

                    return [...prev];
                  })
                }
                fullWidth
                style={{ marginBottom: "15px" }}
                key={index}
              />
            ))}
            <Button
              variant="contained"
              color="primary"
              fullWidth
              style={{ marginBottom: "25px" }}
              onClick={async () => {
                if (sWallets < 1) {
                  toast("Enter a valid number", {
                    type: "error",
                  });
                  return;
                }
                setLoading(true);
                await generateSecondaryWallet(sWallets, secondaryPks);
                await fetchWallets();
                setLoading(false);
              }}
            >
              Add Secondary Wallets
            </Button>
            <FormControl fullWidth style={{ marginBottom: "15px" }}>
              <InputLabel>Source Chain</InputLabel>
              <Select
                value={sourceChain}
                label="Source Chain"
                onChange={(e) => setSourceChain(e.target.value)}
              >
                {chains.map((chain) => (
                  <MenuItem key={chain.networkId} value={chain.networkId}>
                    {chain.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth style={{ marginBottom: "5px" }}>
              <InputLabel>Source Currency</InputLabel>
              <Select
                value={sourceCurrency}
                label="Source Currency"
                onChange={(e) => setSourceCurrency(e.target.value)}
              >
                {tokens[sourceChain].map((token) => (
                  <MenuItem key={token.name} value={token.contractAddress}>
                    {token.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                Balance: {sourceBalance ? sourceBalance : "-"}
              </FormHelperText>
            </FormControl>
            <FormControl fullWidth style={{ marginBottom: "15px" }}>
              <InputLabel>Destination Chain</InputLabel>
              <Select
                value={destinationChain}
                label="Destination Chain"
                onChange={(e) => setDestinationChain(e.target.value)}
              >
                {chains.map((chain) => (
                  <MenuItem key={chain.networkId} value={chain.networkId}>
                    {chain.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth style={{ marginBottom: "5px" }}>
              <InputLabel>Destination Currency</InputLabel>
              <Select
                value={destinationCurrency}
                label="Destination Currency"
                onChange={(e) => setDestinationCurrency(e.target.value)}
              >
                {tokens[destinationChain].map((token) => (
                  <MenuItem key={token.name} value={token.contractAddress}>
                    {token.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                Balance: {destinationBalance ? destinationBalance : "-"}
              </FormHelperText>
            </FormControl>
            <FormControl
              fullWidth
              component="fieldset"
              style={{ marginBottom: "5px" }}
            >
              <FormLabel component="legend">Select Exchange Type</FormLabel>
              <RadioGroup
                aria-label="exchangeType"
                name="exchangeType"
                value={exchangeType}
                onChange={(e) => setExchangeType(e.target.value)}
                row
              >
                <FormControlLabel
                  value="ANY"
                  control={<Radio />}
                  label="Both/Any"
                />
                <FormControlLabel value="DEX" control={<Radio />} label="DEX" />
                <FormControlLabel value="CEX" control={<Radio />} label="CEX" />
              </RadioGroup>
            </FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isGlobal}
                  onChange={() => setGlobal((prev) => !prev)}
                />
              }
              label={"Global token value"}
              style={{ marginBottom: "15px" }}
            />
            {isGlobal && (
              <>
                <TextField
                  value={amount}
                  variant="outlined"
                  label="Avg. amount to fund per wallet"
                  onChange={(e) => setAmount(parseFloat(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                  helperText={`Total to be sent: ${
                    amount *
                    checked
                      .map((value, index) => (value ? index : -1))
                      .filter((index) => index !== -1).length
                  } - ${
                    maxAmount *
                    checked
                      .map((value, index) => (value ? index : -1))
                      .filter((index) => index !== -1).length
                  }`}
                />
                <TextField
                  value={deviation}
                  variant="outlined"
                  label="Deviation (%)"
                  onChange={(e) => setDeviation(parseFloat(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={maxAmount}
                  variant="outlined"
                  label="Max Amount"
                  onChange={(e) => setMaxAmount(parseFloat(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
              </>
            )}
            <TextField
              value={period}
              variant="outlined"
              label="Period (seconds)"
              onChange={(e) => setPeriod(parseInt(e.target.value))}
              fullWidth
              style={{ marginBottom: "15px" }}
            />
            <TextField
              value={maxPeriod}
              variant="outlined"
              label="Max Period (seconds)"
              onChange={(e) => setMaxPeriod(parseInt(e.target.value))}
              fullWidth
              style={{ marginBottom: "15px" }}
            />
            <TextField
              value={deviationP}
              variant="outlined"
              label="Deviation (%)"
              onChange={(e) => setDeviationP(parseFloat(e.target.value))}
              fullWidth
              style={{ marginBottom: "15px" }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isLimited}
                  onChange={() => setLimited((prev) => !prev)}
                />
              }
              label={"Limit decimals"}
              style={{ marginBottom: "15px" }}
            />
            <br />
            {isLimited && (
              <TextField
                label="Number of decimals"
                type="number"
                value={limit}
                onChange={(e) => setLimit(e.target.value)}
                style={{ marginBottom: "15px" }}
              />
            )}
            <FormControl component="fieldset" style={{ marginBottom: "15px" }}>
              <FormLabel component="legend">Select Speed</FormLabel>
              <RadioGroup
                aria-label="speed"
                name="speed"
                value={label}
                onChange={handleSpeedLabelChange}
                row
              >
                <FormControlLabel
                  value="Slow"
                  control={<Radio />}
                  label="Slow (current)"
                />
                <FormControlLabel
                  value="Medium"
                  control={<Radio />}
                  label="Medium (+10%)"
                />
                <FormControlLabel
                  value="Fast"
                  control={<Radio />}
                  label="Fast (+25%)"
                />
                <FormControlLabel
                  value="Custom"
                  control={<Radio />}
                  label="Custom"
                />
              </RadioGroup>
              {label === "Custom" && (
                <TextField
                  label="Custom (%)"
                  type="number"
                  value={speed}
                  onChange={(e) => setSpeed(e.target.value)}
                  margin="normal"
                  fullWidth
                />
              )}
            </FormControl>
            <Button
              variant="contained"
              color="black"
              fullWidth
              onClick={async () => {
                try {
                  if (
                    sourceCurrency === destinationCurrency &&
                    sourceChain === destinationChain
                  ) {
                    toast("Source and destination asset must be different!", {
                      type: "info",
                    });
                  } else {
                    setLoading(true);
                    const trueIndexes = checked
                      .map((value, index) => (value ? index : -1))
                      .filter((index) => index !== -1);
                    const addressesArray = trueIndexes.map(
                      (index) => secondaryWallets[index]?.address
                    );
                    const selectedTokenAmounts = trueIndexes.map(
                      (index) => tokenAmounts[index]
                    );

                    await distributeFunds(
                      addressesArray,
                      amount,
                      maxAmount,
                      deviation,
                      period,
                      maxPeriod,
                      deviationP,
                      limit,
                      speed,
                      sourceChain,
                      sourceCurrency === "null" ? null : sourceCurrency,
                      destinationChain,
                      destinationCurrency === "null"
                        ? null
                        : destinationCurrency,
                      exchangeType,
                      isGlobal,
                      selectedTokenAmounts
                    );
                    await fetchWallets();
                    toast("Bridging in Progress", {
                      type: "success",
                    });
                    setLoading(false);
                  }
                } catch (e) {
                  toast(e.message, {
                    type: "error",
                  });
                  setLoading(false);
                }
              }}
              disabled={isLoading}
            >
              Fund Selected Wallets
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            {Object.entries(groups)?.map(([groupName, groupWallets]) => (
              <Accordion key={groupName}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  {groupName}
                </AccordionSummary>
                <AccordionDetails>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isAll(groupWallets, checked)}
                        onChange={() => selectAll(groupWallets, checked, setChecked)}
                      />
                    }
                    label={"Select All"}
                    style={{ marginBottom: "5px" }}
                  />
                  {groupWallets?.map((sW) => (
                    <div key={sW?.walletIndex}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id={sW?.walletIndex}
                            checked={checked[sW?.walletIndex]}
                            onChange={handleChange}
                          />
                        }
                        label={
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              cursor: "default",
                            }}
                            onClick={(e) => e.preventDefault()}
                          >
                            {sW?.address}
                            {isEditing[sW?.walletIndex] ? (
                              <IconButton
                                aria-label="Done"
                                onClick={async () => {
                                  setEditing((prev) => {
                                    prev[sW?.walletIndex] = false;
                                    return [...prev];
                                  });
                                  await updateSecondaryWalletGroups(
                                    sW?.address,
                                    sW?.tags || []
                                  );
                                  await fetchWallets();
                                }}
                              >
                                <Done />
                              </IconButton>
                            ) : (
                              <IconButton
                                aria-label="Tags"
                                onClick={async () => {
                                  setEditing((prev) => {
                                    prev[sW?.walletIndex] = true;
                                    return [...prev];
                                  });
                                }}
                              >
                                <Edit />
                              </IconButton>
                            )}
                            {sW?.isHidden ? (
                              <IconButton
                                aria-label="Un-Hide wallet"
                                onClick={async () => {
                                  await unhideSecondaryWallet(sW?.address);
                                  await fetchWallets();
                                }}
                              >
                                <Visibility />
                              </IconButton>
                            ) : (
                              <IconButton
                                aria-label="Hide wallet"
                                onClick={async () => {
                                  await hideSecondaryWallet(sW?.address);
                                  await fetchWallets();
                                }}
                              >
                                <VisibilityOff />
                              </IconButton>
                            )}
                            <CopyButton text={sW?.address} />
                            <PrivateKeyButton
                              isPrimary={false}
                              index={sW?.walletIndex}
                            />
                            <IconButton
                              aria-label="Remove wallet"
                              onClick={async () => {
                                await removeSecondaryWallet(
                                  sW?.walletIndex,
                                  sW?.address
                                );
                                await fetchWallets();
                              }}
                            >
                              <Close color="secondary" />
                            </IconButton>
                          </div>
                        }
                      />
                      {!(!checked[sW?.walletIndex] || isGlobal) && (
                        <TextField
                          value={tokenAmounts[sW?.walletIndex]}
                          variant="outlined"
                          label="Enter Amount"
                          onChange={(e) =>
                            setTokenAmounts((prev) => {
                              prev[sW?.walletIndex] = parseFloat(
                                e.target.value
                              );
                              return [...prev];
                            })
                          }
                          fullWidth
                          disabled={!checked[sW?.walletIndex] || isGlobal}
                        />
                      )}
                      <FormHelperText>{`Balance: ${
                        balances ? balances[sW?.walletIndex] : "-"
                      }`}</FormHelperText>
                      {isEditing[sW?.walletIndex] && (
                        <>
                          {sW?.tags?.map((tag, index) => (
                            <Chip
                              key={index}
                              label={tag}
                              onDelete={() =>
                                setSecondaryWallets((prev) => {
                                  let newTags = prev[
                                    sW?.walletIndex
                                  ].tags?.filter((tagg) => {
                                    return tagg !== tag;
                                  });
                                  prev[sW?.walletIndex].tags = newTags;
                                  return [...prev];
                                })
                              }
                              style={{ margin: "5px", opacity: 0.7 }}
                              color="primary"
                            />
                          ))}
                          <IconButton
                            aria-label="Add tag"
                            onClick={async () => {
                              setDialogOpen(true);
                            }}
                          >
                            <Add />
                          </IconButton>
                          <Dialog
                            open={dialogOpen}
                            onClose={() => setDialogOpen(false)}
                          >
                            <DialogTitle>Add a new tag</DialogTitle>
                            <DialogContent>
                              <TextField
                                autoFocus
                                margin="dense"
                                label="New Tag"
                                type="text"
                                fullWidth
                                value={newTag}
                                onChange={(e) => setNewTag(e.target.value)}
                              />
                            </DialogContent>
                            <DialogActions>
                              <Button
                                onClick={() => {
                                  setDialogOpen(false);
                                  setNewTag("");
                                }}
                                color="primary"
                              >
                                Cancel
                              </Button>
                              <Button
                                onClick={async () => {
                                  if (newTag.trim() === "") {
                                    toast("Enter a non-empty tag name", {
                                      type: "error",
                                    });
                                    return;
                                  } else {
                                    setSecondaryWallets((prev) => {
                                      let newTags = prev[sW?.walletIndex].tags;
                                      newTags.push(newTag);
                                      prev[sW?.walletIndex].tags = newTags;
                                      return [...prev];
                                    });
                                    setNewTag("");
                                    setDialogOpen(false);
                                  }
                                }}
                                color="primary"
                              >
                                Add
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </>
                      )}
                    </div>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export default Wallet;
