import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getLogs } from "../../api/api";
import { ExpandMore } from "@mui/icons-material";

function Logs() {
  const [iLogs, setInternalLogs] = useState(null);
  const [eLogs, setErrorLogs] = useState(null);
  const [dLogs, setDebugLogs] = useState(null);

  const fetchLogs = async () => {
    const { internalLogs, errorLogs, debugLogs } = await getLogs();
    setInternalLogs(internalLogs);
    setErrorLogs(errorLogs);
    setDebugLogs(debugLogs);
  };

  useEffect(() => {
    fetchLogs();
  }, []);

  return (
    <>
      <Typography variant="h4" mb={2}>
        Logs
      </Typography>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Server Error Logs
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ maxHeight: "70vh", overflow: "auto" }}>
            <Typography variant="body2">
              <pre style={{ whiteSpace: "pre-wrap" }}>{eLogs}</pre>
            </Typography>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Server Debug Logs
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ maxHeight: "70vh", overflow: "auto" }}>
            <Typography variant="body2">
              <pre style={{ whiteSpace: "pre-wrap" }}>{dLogs}</pre>
            </Typography>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Server Internal Logs
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ maxHeight: "60vh", overflow: "auto" }}>
            <Typography variant="body2">
              <pre style={{ whiteSpace: "pre-wrap" }}>{iLogs}</pre>
            </Typography>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default Logs;
