import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState, useRef } from "react";
import {
  distributeToWallets,
  getPrimaryWallet,
  getSecondaryWallets,
  getTokenInfo,
} from "../../api/api";
import { toast } from "react-toastify";
import Papa from "papaparse";

function Distribution() {
  const [primaryWallet, setPrimaryWallet] = useState("");
  const [secondaryWallets, setSecondaryWallets] = useState(null);
  const [period, setPeriod] = useState(0);
  const [maxPeriod, setMaxPeriod] = useState(0);
  const [deviationP, setDeviationP] = useState(0);
  const [maxHolding, setMaxHolding] = useState(0);
  const [tokenAddress, setTokenAddress] = useState("");
  const [name, setName] = useState("");
  const [symbol, setSymbol] = useState("");
  const [csvData, setCsvData] = useState([]);

  const [isLoading, setLoading] = useState(false);

  const [speed, setSpeed] = useState(0);
  const [label, setLabel] = useState("Slow");
  const [isLimited, setLimited] = useState(false);
  const [limit, setLimit] = useState(-1);

  const fileInputRef = useRef(null);

  const fetchWallets = async () => {
    const pW = await getPrimaryWallet();
    setPrimaryWallet(pW?.toLowerCase());

    const { sWs } = await getSecondaryWallets();
    setSecondaryWallets(sWs?.map((addr) => addr.toLowerCase()));
  };

  const getWalletIndex = (address) => {
    if (address.toLowerCase() === primaryWallet) {
      // Primary
      return -2;
    } else {
      const index = secondaryWallets.indexOf(address.toLowerCase());
      return index;
    }
  };

  const handleFileUpload = (event) => {
    setLoading(false);
    const file = event.target.files[0];

    if (file) {
      Papa.parse(file, {
        header: true, // Parse the header row to extract column names
        skipEmptyLines: true, // Skip empty lines in the CSV file
        complete: function (result) {
          setCsvData(
            result.data.map((w) => ({
              ...w,
              index: getWalletIndex(w.source),
            })).filter((w) => w.index !== -1)
          );
          // Clear the file input after the state has been updated
          fileInputRef.current.value = null;
        },
      });
    }
  };

  const handleSpeedLabelChange = (e) => {
    setLabel(e.target.value);
  };

  const fetchTokenInfo = async () => {
    try {
      const { name, symbol } = await getTokenInfo(tokenAddress);
      setName(name);
      setSymbol(symbol);
    } catch (err) {
      toast(err.message, {
        type: "error",
      });
    }
  };

  useEffect(() => {
    if (tokenAddress && tokenAddress.trim() !== "") {
      fetchTokenInfo();
    } else {
      setSymbol("ETH");
      setName("ETH");
    }
  }, [tokenAddress]);

  useEffect(() => {
    if (label) {
      if (label === "Slow") {
        setSpeed(0);
      } else if (label === "Medium") {
        setSpeed(10);
      } else if (label === "Fast") {
        setSpeed(25);
      }
    }
  }, [label]);

  useEffect(() => {
    if (isLimited) {
      setLimit(0);
    } else {
      setLimit(-1);
    }
  }, [isLimited]);

  useEffect(() => {
    fetchWallets();
  }, []);

  return (
    <>
      <Typography variant="h4" mb={2}>
        Distribution Module
      </Typography>
      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid container spacing={4} padding={2}>
          <Grid item xs={12} md={6}>
            <TextField
              value={tokenAddress}
              variant="outlined"
              label="Token Address (Empty for ETH)"
              onChange={(e) => setTokenAddress(e.target.value)}
              fullWidth
              helperText={`Name: ${name}, Symbol: ${symbol}`}
              style={{ marginBottom: "10px" }}
            />
            <TextField
              value={maxHolding}
              variant="outlined"
              label="Max Holding Limit"
              onChange={(e) => setMaxHolding(parseFloat(e.target.value))}
              fullWidth
              style={{ marginBottom: "15px" }}
            />
            <TextField
              value={period}
              variant="outlined"
              label="Period (seconds)"
              onChange={(e) => setPeriod(parseInt(e.target.value))}
              fullWidth
              style={{ marginBottom: "15px" }}
            />
            <TextField
              value={maxPeriod}
              variant="outlined"
              label="Max Period (seconds)"
              onChange={(e) => setMaxPeriod(parseInt(e.target.value))}
              fullWidth
              style={{ marginBottom: "15px" }}
            />
            <TextField
              value={deviationP}
              variant="outlined"
              label="Deviation (%)"
              onChange={(e) => setDeviationP(parseFloat(e.target.value))}
              fullWidth
              style={{ marginBottom: "15px" }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isLimited}
                  onChange={() => setLimited((prev) => !prev)}
                />
              }
              label={"Limit decimals"}
              style={{ marginBottom: "15px" }}
            />
            <br />
            {isLimited && (
              <TextField
                label="Number of decimals"
                type="number"
                value={limit}
                onChange={(e) => setLimit(e.target.value)}
                style={{ marginBottom: "15px" }}
              />
            )}
            <FormControl component="fieldset" style={{ marginBottom: "15px" }}>
              <FormLabel component="legend">Select Speed</FormLabel>
              <RadioGroup
                aria-label="speed"
                name="speed"
                value={label}
                onChange={handleSpeedLabelChange}
                row
              >
                <FormControlLabel
                  value="Slow"
                  control={<Radio />}
                  label="Slow (current)"
                />
                <FormControlLabel
                  value="Medium"
                  control={<Radio />}
                  label="Medium (+10%)"
                />
                <FormControlLabel
                  value="Fast"
                  control={<Radio />}
                  label="Fast (+25%)"
                />
                <FormControlLabel
                  value="Custom"
                  control={<Radio />}
                  label="Custom"
                />
              </RadioGroup>
              {label === "Custom" && (
                <TextField
                  label="Custom (%)"
                  type="number"
                  value={speed}
                  onChange={(e) => setSpeed(e.target.value)}
                  margin="normal"
                  fullWidth
                />
              )}
            </FormControl>
            <Button
              variant="contained"
              color="black"
              fullWidth
              onClick={async () => {
                try {
                  setLoading(true);

                  await distributeToWallets(
                    tokenAddress,
                    maxHolding,
                    period,
                    maxPeriod,
                    deviationP,
                    limit,
                    speed,
                    csvData
                  );
                  toast("Distribution in Progress", {
                    type: "success",
                  });
                } catch (e) {
                  toast(e.message, {
                    type: "error",
                  });
                } finally {
                  setLoading(false);
                }
              }}
              disabled={isLoading}
            >
              Distribute
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <input
              type="file"
              accept=".csv"
              ref={fileInputRef}
              onChange={async (e) => {
                try {
                  handleFileUpload(e);
                } catch (e) {
                  toast(e.message, {
                    type: "error",
                  });
                  setLoading(false);
                }
              }}
            />
            {csvData.length > 0 && (
              <div>
                <h3>Data:</h3>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>source</TableCell>
                      <TableCell>index</TableCell>
                      <TableCell>destination</TableCell>
                      <TableCell>amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {csvData?.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {row.source.substring(0, 7)}...
                          {row.source.substring(
                            row.source.length - 5,
                            row.source.length
                          )}
                        </TableCell>
                        <TableCell>
                          {row?.index === -2
                            ? "P"
                            : row?.index === -1
                            ? "Non-BM"
                            : `S${row?.index}`}
                        </TableCell>
                        <TableCell>
                          {row.destination.substring(0, 7)}...
                          {row.destination.substring(
                            row.destination.length - 5,
                            row.destination.length
                          )}
                        </TableCell>
                        <TableCell>{row.amount}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            )}
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export default Distribution;
